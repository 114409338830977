import { CLIENT_ID, MONOLITH_API_PREFIX } from 'src/consts';
import { CATALOG_SERVICE_API_PREFIX } from 'src/consts/third_party';
import { DELETE, GET, POST } from 'src/utils/request';

export function getQueryMealsUrl(restaurantId) {
  return `${MONOLITH_API_PREFIX}/api/restaurant/v4/restaurants/${restaurantId}/meal_instances`;
}

export function fetchRestaurantMenus({ restaurant_id, page = 1, page_size = 100 }) {
  return GET(`${CATALOG_SERVICE_API_PREFIX}/api/v1/pos_dashboard/restaurants/${restaurant_id}/menus`, {
    page,
    page_size,
  });
}

export function queryMealsByFilters({
  restaurant_id,
  distribution_modes,
  meal_prototype_ids,
  page = 1,
  page_size = 100,
  menu_ids,
  category_ids,
  search_term,
}) {
  return GET(
    getQueryMealsUrl(restaurant_id),
    {
      distribution_modes,
      meal_prototype_ids,
      page,
      page_size,
      valid: false,
      menu_ids,
      category_ids,
      search_term,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function deleteMealInstances({ restaurant_id, meal_ids }) {
  return DELETE(
    `${MONOLITH_API_PREFIX}/api/restaurant/v4/restaurants/${restaurant_id}/meal_instances`,
    { ids: meal_ids },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
    }
  );
}

export function batchCreateMealInstances({ restaurant_id, payload }) {
  return POST(
    `${MONOLITH_API_PREFIX}/api/restaurant/v4/restaurants/${restaurant_id}/meal_instances/build`,
    { meal_instances: payload },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
    }
  );
}
