import { CHOWBUS_MENU_NAME_MAPPING } from 'src/consts';
import { getItemName } from 'src/utils/utils';
import { fetchRestaurantMenus, queryMealsByFilters } from 'src/services/menuBuilder';
import { getSessionItem, USER_KEYS } from 'src/store/storage';
import { validateMeal } from 'src/components/MenuManagement/MenuBuilder/utils';

/**
 * 获取菜品完整路径
 * @param {Object} params
 * @param {Object} params.meal 菜品
 * @param {String} params.locale 当前语言
 * @param {String} params.delimeter 隔断符
 * @returns {String}
 */
export function getMealFullPath({ meal, locale, delimeter = '-' }) {
  const { menu, category, restaurant_contract, meal_prototype } = meal;
  const { distribution_mode } = restaurant_contract;
  const contractName = getItemName(CHOWBUS_MENU_NAME_MAPPING[distribution_mode], locale);
  const menuName = getItemName(menu, locale);
  const categoryName = getItemName(category, locale);
  const mealName = getItemName(meal_prototype, locale);
  return [contractName, menuName, categoryName, mealName].join(` ${delimeter} `);
}

/**
 * 获取餐厅所有菜单
 * @param {Object} params
 * @param {String} params.restaurant_id 餐厅id
 * @param {Number} params.page 当前页面
 * @param {Number} params.page_size 每页显示条数
 * @param {Array<Array<Menu>} param.totalPageData 所有menu数据
 * @returns Response<Array<Array<Menu>>
 */
const loadRestaurantMenus = async ({ restaurant_id, page = 1, page_size, totalPageData }) => {
  let response;

  try {
    response = await fetchRestaurantMenus({ restaurant_id, page, page_size });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  const { data: menus, page: page_meta } = response.data;
  const { total, page: current_page } = page_meta;
  const total_pages = Math.ceil(total / page_size);
  // const {
  //   menus,
  //   meta: { pages },
  // } = response.data;
  // const { current_page, total_pages } = pages;
  if (totalPageData) {
    totalPageData.length = total_pages;
  } else {
    totalPageData = Array(total_pages).fill(null);
  }
  totalPageData[page - 1] = menus;

  if (current_page === 1 && total_pages > current_page) {
    const promises = [];

    for (let i = 2; i <= total_pages; i++) {
      const promise = loadRestaurantMenus({ restaurant_id, page: i, page_size, totalPageData });
      promises.push(promise);
    }

    await Promise.all(promises);
  }

  return { success: true, data: totalPageData };
};

/**
 * 获取餐厅所有菜单
 * @param {String} restaurantId 餐厅id
 * @returns Response<Array<Menu>>
 */
export async function loadAllMenusOfRestaurant(restaurantId) {
  const response = await loadRestaurantMenus({
    restaurant_id: restaurantId,
    page: 1,
    page_size: 100,
    totalPageData: [],
  });

  if (!response.success) return response;

  const menus = (response.data || []).reduce((prev, current) => {
    if (current && current.length) prev.push(...current);
    return prev;
  }, []);
  menus.sort((a, b) => (a.sequence || 0) - (b.sequence || 0) || a.id - b.id);
  menus.forEach((menu) => {
    if (menu.categories && menu.categories.length) {
      menu.categories.sort((a, b) => (a.sequence || 0) - (b.sequence || 0) || a.id - b.id);
    }
  });
  return { success: true, data: menus };
}

async function loadMealsPageDataByFilter({ page = 1, pageSize = 100, totalPageData, ...other }) {
  const restaurantId = getSessionItem(USER_KEYS.restaurantId);
  const params = {
    restaurant_id: restaurantId,
    page,
    page_size: pageSize,
    ...other,
  };

  let response;

  try {
    response = await queryMealsByFilters(params);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  const {
    data,
    meta: { pages },
  } = response;
  const { current_page, total_pages } = pages;
  if (!totalPageData) totalPageData = Array(total_pages).fill(null);
  totalPageData[page - 1] = data;

  if (current_page === 1 && total_pages > current_page) {
    const promises = [];

    for (let i = 2; i <= total_pages; i++) {
      const promise = loadMealsPageDataByFilter({ page: i, pageSize, totalPageData, ...other });
      promises.push(promise);
    }

    await Promise.all(promises);
  }

  return { success: true, data: totalPageData };
}

/**
 * 根据条件获取菜品数据
 * @param {Object} payload
 * @param {string} payload.restaurant_id 餐厅id
 * @param {string[]} payload.meal_prototype_ids 菜品原型ids optional
 * @param {string[]} payload.distribution_modes 合约modes optional
 * @param {string[]} payload.menu_ids 菜单ids optional
 * @param {string[]} payload.category_ids 分类ids
 * @returns
 */
export async function fetchAllMealsByFilter(payload) {
  const response = await loadMealsPageDataByFilter({
    ...payload,
    page: 1,
    pageSize: 100,
  });
  if (!response.success) return response;

  const meals = response.data.reduce((prev, current) => {
    if (current && current.length) {
      current.forEach((meal) => {
        if (validateMeal(meal)) {
          prev[meal.id] = {
            ...meal,
            menu_id: meal.menu.id,
            category_id: meal.category.id,
            name: meal.meal_prototype.name,
            foreign_name: meal.meal_prototype.foreign_name,
          };
        }
      });
    }
    return prev;
  }, {});
  return { success: true, data: meals };
}

/**
 * 按排序值和id至对菜品进行排序, sequence升序排序， sequence相同时按id升序排序
 * @param {Meal[]} meals 菜品数组
 */
export function sortMealsBySequenceAndId(meals) {
  if (!meals || !meals.length) return;
  meals.sort((a, b) => {
    let comparor = (a.sequence_num || 0) - (b.sequence_num || 0);
    if (!comparor) comparor = a.id - b.id;
    return comparor;
  });
}
